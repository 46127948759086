.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login{
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-image: linear-gradient(to top,   #09203f 0%, #537895 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: LatoWeb, "Lato Extended", Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  
}

.login-container{
  margin-left: auto;
  margin-right: auto;
  position: relative;
  border-style: solid;
  border-color: white;
  width: 300px;
  height: auto;
  background-color: white;
  border-radius: 3px;
  padding: 20px;
  padding-bottom: 0px;
  text-align: left;
}

input{
  width: 100%;
  height: 30px;
  border: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: gray;
  text-align: left;
  font-size: 15px;
  margin-bottom: 10px;
  
}

label{
  text-align: left;
  font-size: 12px;
}

.logo{
  text-align: center;
  margin: 55px;
  font-weight: bolder;
  color: white;
  width: 120px;
  margin-left: auto;
  margin-right: auto;
  border-width: 1px;
  border-radius: 3px;
  padding-top: 6px;
}

.logo .icon{
  font-size: 55px;
  text-shadow: 2px 2px 3px black;
  margin-bottom: -15px;
  padding: 0;
}

h4{
  text-align: center;
  font-weight: bolder;
  font-size: 20px;
  color: #09203f;
}

.login-button{
  margin: -3px;
}

.google{
  margin-top: 20px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}





input:focus, textarea:focus, select:focus{
        outline: none;
}

.sign-up{
  padding-top: 20px;
  border-top-style: solid;
  margin-left: -22px;
  margin-right: -22px;
  border-color: gray;
  border-width: 1px;
  background-color: #f5f2f2;
  padding-bottom: 20px;
}

a{
  font-weight: bolder;
}


.sidebar{
  position: fixed;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
  background-image: linear-gradient(to top,   #09203f 0%, #537895 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  padding-top: 50px;
  transition: all .2s ease-in-out;
}

.side-open{
  left: -52px;
}

.burger-button-wrap{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all .5s ease-in-out;
  top: 15px;
  left: 16px;
  z-index: 1;
}

.burger-button{
  width: 30px;
  height: 4px;
  background: #09203f;
  border-radius: 1px;
  transition: all .5s ease-in-out;
}

.burger-button::before,
.burger-button::after{
  content: '';
  position: absolute;
  width: 30px;
  height: 4px;
  background: #537895;
  border-radius: 1px;
  transition: all .5s ease-in-out;
}

.burger-button::before{
  transform: translateY(-8px);
}

.burger-button::after{
  transform: translateY(8px);
}

.burger-button-wrap.open .burger-button{
  transform: translateX(-30px);
  background: transparent;
  box-shadow: none;
  
}

.burger-button-wrap.open .burger-button::before{
  transform: rotate(45deg) translate(20px, -20px);
  background: white;
  
}

.burger-button-wrap.open .burger-button::after{
  transform: rotate(-45deg) translate(20px, 20px);
  background: white;
}

.nav-icon{
  width: 100%;
  text-align: center;
  color: white;
  padding-bottom: 5px;
  padding-top: 5px;
  transition: all .3s ease-in-out;
  cursor: pointer;
}

.nav-icon:hover{
  background-color: #537895;
}

.nav-icon .title{
  font-size: 12px;
  font-weight: bolder;
}

.nav-icon i{
  font-size: 24px;
}

.title{
  margin-top: -5px;
}

.l-text{
  opacity: 0;
}

.instructor-log-selection{
  display: flex;
  align-content: center;
  align-items: center;
}

.reports-modal-body button{
 width: 180px;
 height: 30px;
 margin: 5px;
}

.reports-modal-body{
  display: flex;
  padding: 5px;
}

.report-options-title{
  text-align: center;
  text-decoration: underline;
}

.instructor-log-selection .dropdown{
  width: 180px;
  height: 30px;
}

.instructor-log-selection .dropdown-toggle{
  width: 180px;
  height: 30px;
  padding: 0px;
  margin: 0px;
}

.instructor, .course, .student{
  height: 90px;
  border-color: rgb(179, 179, 179);
  border-width: 1px;
  border-bottom-style: solid;
  width: 100%;
  overflow: hidden; 
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.instructor .id, .login_id{
  display: none;
}

.instructor .profile-pic{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border-style: solid;
  border-width: .5px;
  top: -60px;
  left: 10px;
  background-color: #fff;
  text-align: center;
  overflow: hidden;
}
.student .profile-pic{
  display: flex;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border-style: solid;
  border-width: .5px;
  margin: 0px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.instructor .profile-pic img{
  width: 70px;
  height: 70px;
  margin-right: 4px;
}

.person{
  display: flex;
  padding: 4px;
  align-items: center;
  margin-top: auto;
    margin-bottom: auto;
    border-width: 1px;
    border-color: #cccbcb
}

.instructor .name{
  width: 146px;
  height: 100%;
  text-align: center;
  top: 16px;
  left: 90px;
  font-weight: 600;
  color: #545557;
  font-size: 15px;
  padding: 4px;
  
      
}

.student .name{
  width: 146px;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0px;
  left: 90px;
  font-weight: 600;
  color: #545557;
  font-size: 15px;
}

.student .name:hover{
  color: black;
  cursor: pointer;
  text-decoration: underline;
}

.course .course-name{
  font-weight: 600;
  color: #545557;
  font-size: 17px;
  background-color: white;
  width: 214px;
  padding-left: 8px;
}

.course .course-name:hover{
  cursor: pointer;
  color: black;
}

.course .student-count{
  text-align: center;

  width: 70px;

  color: #979494;
  font-weight: bold;
}

.student-count .student-count-text{
  color: #545557;
  font-size: 18px;
}

.avg-grade .student-count-text{
  color: #545557;
  font-size: 18px;
}

.instructor .course-count{
  text-align: center;
  width: 70px;
  color: #979494;
  font-weight: bold;
}

.student .missing-assignments {
  text-align: center;
  color: #979494;
  font-weight: bold;
  cursor: pointer;
}

.student .missing-assignments:hover{
  color: #000000;
}

.student .missing-assignments a {
  color: #979494;
  font-weight: bold;
}

.student .progress{
  margin-top: 4px;
}

.instructor .ungraded-assignments{
  text-align: center;
  width: 100px;
  color: #979494;
  font-weight: bold;
}

.instructor .updates-per-week{
  text-align: center;
  width: 100px;
  color: #979494;
  font-weight: bold;
}

.instructor .updates-count{
  color: #545557
}

.sort .updates-per-week{
  display: flex;
  align-items: center;
  font-size: 12px;
  color: black;
  cursor: pointer;
}


.course .avg-grade{
  text-align: center;
  width: 100px;
  color: #979494;
  font-weight: bold;
}

.instructor .participation-container{
  display: flex;
  width: 320px;
  height: 100%;
  font-weight: bold;
  color: #545557;
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  
}

.student .participation-container {
  display: flex;
  font-weight: bold;
  font-size: 20px;
  overflow: hidden;
  position: relative;
  margin-top: -10%;
  margin-bottom: -10%;
  height: 100%;
  align-content: center;
  width: 220px;
}

.student .bar-title{
  width: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.progress-bar-striped{
  border-radius: 2px;
}

.participation-container .participation-bar{
  width: 100%;
  border-radius: 10px;
  margin: 4px;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.course .participation-container .participation-bar{
  width: 220px;
  border-radius: 10px;
}

.course .participation-container{
  display: flex;
  width: 200px;
  align-items: center;
  font-weight: bold;
  color: #545557;
  font-size: 20px;
  height: 100%;
}

.inspect {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgb(255, 255, 255);
  z-index: 1;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  transition: opacity .2s;
}

.inspect-elem{
  position: relative;
  width: 50px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: -10px;
  height: 100%;
  top: 21%;

}

.inspect-elem-title{
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 8px;
  top: -10px;
  width: 50px;
}

.inspect:hover{
  opacity: 1;
}

.identity{
  text-align: right;
  width: 100px;
  overflow: hidden;
}

.inspect-wrapper{
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: center;
  width: 100%;
}

.bar-title{
  width: 130px;
  text-align: right;
}


.course .passrate-container .passrate-bar{

  width: 220px;

  border-radius: 10px;
  text-align: right;
}

.course .passrate-container{
  display: flex;
  width: 300px;
  align-items: center;
  font-weight: bold;
  color: #545557;
  font-size: 20px;
}

.ungraded-assignments .ungraded-count{
  color: #34df97;
  font-size: 19px;
}

.course-count .count-text{
  color: #545557;
  font-size: 18px;
}


.list-container{
  padding: 50px;
  padding-top: 20px;
  justify-content: center;
}

.search-bar{
  width: 400px;
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  text-align: left;
  color: #cecece;
  padding-left: 8px;
  font-weight: 600;
  right: 100px;
  top: -23px;
  border-color: #cecece;
}

.search-bar:focus{
  color: #000000;
}

.ButtonGroup{
  padding-left: 10px;
}

.sort-selector{
  position: relative;
  left: 440px;
  font-weight: 500;
  border-color: #c41e3a;
}

.aggregate-toggle{
  width: 129px;
}

.aggregate-toggle .toggle-box{
  width: 20px;
  height: 20px;
}

.btn-group, .btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  left: 37px;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
  color: #000000;
  background-color: #fff;
  border-color: #000000;
  border-style: none;
  text-decoration: underline;
  font-size: 18px;
  font-weight: bolder;
}

.btn-secondary.disabled{
  background-color: white;
  color: #545557;
  border-style: none;
  font-size: 18px;
}

.btn-secondary.disabled:hover{
  background-color: white;
  color: #545557;
  border-style: none;
  font-size: 18px;
  text-decoration: none;
}

.btn-secondary {
  color: #005dc3;
  background-color: #fff;
  border-style: none;
  font-size: 18px;
}

.btn-secondary:hover{
  text-decoration: underline;
  background-color: #fff;
  border-style: none;
  color: #005dc3;
}

.btn-secondary.focus{
  box-shadow: none;
}

.student .trend-container{
  width: 70px;
  color: #545557;
  font-size: 18px;
  font-weight: bold;
}

.trend-arrow{
  margin: auto;
  padding-left: 15px;
  font-size: 25px;
  color: #f8cf48;
}

.card-sort-toggle{
  width: 100%;
  height: 20px;
  background-color: red;
  display: flex;
  justify-content: space-between;
}

.sort{
  height: 50px;
  box-shadow: none;
  color: black;
  font-size: 12px;
  border-bottom-style: solid;
  border-bottom-color: rgb(179, 179, 179);
  border-bottom-width: .5px;
  border-radius: 0px;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
}

.sort svg{
  margin-left: 2px;
}

.sort .student-count, .sort .avg-grade{
  display: flex;
  align-items: center;
  color: black;
}

.sort .course-name{
  display: flex;
  align-items: center;
  font-size: 12px;
  color: black;
}

.sort .student-count{
  width: 100px;
  color: black;
}

.sort .participation-container{
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 12px;
}

.sort .bar-title{
  text-align: right;
  align-items: center;
  font-size: 12px;
  color: black;
  display: flex;
  justify-content: center;
  width: 100%;
}

.fa-arrows-alt-v{
  padding-left: 4px;
  font-size: 20px;
}

.sort .multiple-bar-container{
  align-items: center;
}

.sort .student-count:hover, .sort .avg-grade:hover, .sort .bar-title:hover{
  cursor: pointer;
  font-weight: bolder;
}

.instructor .name:hover{
  text-decoration: underline;
  font-weight: bolder;
  cursor: pointer;
  color: black;
}

.sort .name{
  display: flex;
  align-items: center;
  font-size: 12px;
  color: black;
}

.sort .name:hover{
  text-decoration: none;
  font-weight: bolder;
  cursor: pointer;
}

.sort .person{
  display: flex;
  align-items: center;
  border-style: none;
  height: 100%;
}

.sort .profile-pic{
  display: flex;
  align-items: center;
  border-style: none;
}

.sort .course-count{
  display: flex;
  align-items: center;
  color: black;
}

.sort .course-count:hover{
  font-weight: bolder;
  cursor: pointer;
}

.sort .ungraded-assignments:hover{
  font-weight: bolder;
  cursor: pointer;
}

.sort .ungraded-assignments{
  display: flex;
  align-items: center;
  color: black;
}

.sort .participation-container:hover{
  font-weight: bolder;
  cursor: pointer;
}

.sort .participation-container{
  display: flex;
  align-items: center;
  color: black;
  text-align: center;
  justify-content: center;
}

.sort .trend-container{
  display: flex;
  align-items: center;
  color: black;
  font-size: 12px;
}

.sort .trend-container:hover{
  cursor: pointer;
}

.sort .missing-assignments{
  color: black;
  display: flex;
  align-items: center;
}

.sort .missing-assignments:hover{
  cursor: pointer;
}

.sort .page-csv-download{
  position: absolute;
  font-size: 15px;
  color: black;
  right: 0px;
}

.course-title{
  
  position: absolute;
    left: 355px;
    width: 290px;;
    top: 8px;
  font-size: 15px;
  overflow: hidden;
  display: flex;
  
}

.roster-sync{
  display: inline;
  font-size: 12px;
  text-align: center;
  position: absolute;
  left: 22px;
  top: 2px;
  border-style: solid;
  border-width: 1px;
  padding: 0px;
  margin: 0px;
  border-radius: 10px;
  padding: 3px;
  background-color: #005dc3;
  color: white;
  padding-left: 3px;
}

.roster-sync:hover{
  cursor: pointer;
}

.active{
  background-color: gray;
}

.active svg{
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.course-average-grade, .course-live-class-count{
  margin-right: 20px;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.course-metrics{
  display: flex;
  margin-left: 50px;
  left: 0px;
  top: 50px;
  font-size: 14px;
  align-content: space-between;
  align-items: center;
}

.course-metrics .course-title{
  position: inherit;
  width: auto;
  margin-right: 40px;
  font-size: 18px;
  font-weight: bolder;
}

.selected{
  border-style: solid;
  border-color: #005dc3;
  border-width: 2px;
  box-shadow: none;
  background-color: rgb(217, 222, 255);
  
}

.log-entry-buttons{
  display: inline;
  position: absolute;
  right: 50px;
  top: 86px;
  

}

.log-entry-buttons button{
  margin:0 auto;
  display:block;
  width: 150px;
  margin-bottom: 10px;
  background-color: #0B63C5;
  color: white;
  border-radius: 6px;
  border-style: none;
}

.log-entry-button{
  background-color: #0B63C5;
  color: white;
  border-radius: 6px;
  border-style: none;
}

.search-wrapper button{
 
    margin: 0 auto;
    display: block;
    width: 92px;
    background-color: #0B63C5;
    color: white;
    border-radius: 6px;
    border-style: none;
    height: 30px;
}

.log-entry-buttons button svg{
  margin-right: 5px;
  font-size: 13px;
}

.log-entry-buttons #log-entry{
  position: absolute;
  right: 0px;
  top: -14px;
}

.log-entry-button{
  width: auto;
  position: relative;
  margin: 0;
  padding: 4px;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin: 3px;
    background: #f5f5f5;
    color: #2D3B45;
    border: 1px solid;
    border-color: #C7CDD1;
    border-radius: 3px;
    transition: background-color 0.2s ease-in-out;
    position: relative;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
    text-shadow: none;
    -webkit-user-select: none;
    user-select: none;
}

.log-entry-button:hover{
  background-color: #c4c2c2;
}

.log-entry-button:disabled{
  opacity: .5;
  background: #f5f5f5;
  cursor: not-allowed;
}

.log-buttons{
  display: flex;
  height: 33px;
  padding-left: 10px;
  position: relative;
  right: -250px;
  top: 18px;
}

.disabled{
  opacity: .5;
}

.disabled:hover{
  background: #f5f5f5;
  cursor: not-allowed;
}

.header-bar{
  display: flex;
  align-content: center;
  height: 20px;
  justify-content: space-between;
  margin-left: 50px;
  margin-right: 50px;
}

.header-bar-instruct{
  display: flex;
  align-content: center;
  height: 20px;
  justify-content:flex-end;
  margin-left: 50px;
  margin-right: 50px;
  width: 100%;
}

.student-dropdown{
  display: flex;
  justify-content: space-between;
}

.dropdown-menu{
  max-height: 400px;
  overflow-y: scroll;
}

.dropdown-selection{
  padding-left: 10px;
  width: 20px;
}

.modal-header .close {
  padding: 0;
  margin: 0;
}

.modal-title{
  font-size: 20px;
}

.modal-body textarea{
  width: 100%;
  padding: 5px;
  font-size: 12px;
  height: 300px;
}

.modal-dialog {
  width: 650px;
  max-width: 650px;
}

.modal-body input[type="text"]{
  font-size: 12px;
  border-style: solid;
  border-radius: 2px;
  border-width: 1px;
  border-color: #616161;
  padding-left: 5px;
}

.close span{
  margin-bottom: 10px;
}

.log-entry-head-wrap{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  
}

.entry-type{
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.entry-type input[type="checkbox"]{
  width: 20px;
  height: 20px;
}

.entry-type input{
  margin-top: auto;
  margin-bottom: auto;
  height: auto;
}



.contact-wrapper{
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}

.contact-wrapper label{
  padding-left: 5px;
  padding-right: 2px;
  opacity: .5;
}

.entry-pin-toggle{
  margin-left: 10px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.pin-notify{
  font-size: 8px;
  margin-top: -15px;
  opacity: 0;
}

.pin-enabled{
  font-size: 8px;
  margin-top: -10px;
  opacity: 1;
}

.contact-date{
  width: 140px;
  display: none;
}

.contact-enabled{
  display: block;
  width: 140px;
  margin-top: auto;
  margin-bottom: auto;
}

.entry{
  border: #838383;
  border-style: solid;
  border-width: .25px;
  border-radius: 5px;
  font-size: 10px;
  margin-bottom: 20px;
  position: relative;
  width: 70%;
  margin-left: 50px;
  padding: 10px;
  padding-top: 15px;

}

.entry-info-wrap{
  display: flex;
}

.entry .body{
  font-size: 12px;
  background-color: white;
  overflow-wrap: break-word;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  white-space: pre-wrap;
  max-height: 350px;
  overflow-y: scroll;
}

.entry .body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}


.entry .body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.entry .head{
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
}

.entry .foot{
  display: flex;
  min-height: 12px;
}

.entry .subject{
  font-weight: bold;
  width: 20%;
}

.entry .head .type, .contact{
  height: 100%;
  display: flex;
  font-size: 13px;
  font-style: italic;
  margin-top: auto;
  margin-bottom: auto;
}

.entry .contact{
  width: 90px;
  margin-right: 20px;
}

.entry .badge-wrap{
  display: flex;
  margin-right: 20px;
}

.entry .badge{
  font-weight: 500;
  padding: 4px;
  margin: auto;
}

.entry .submitted-at{
  margin-right: 20px;
  font-style: italic;
}

.entry .author{
  font-style: italic;
}

.entry .is-pinned{
  position: relative;
  right: -2px;
  top: 2px;
  width: 54px;
  margin-left: 10px;
  margin-right: -5px;
  text-align: center;
}



.entry .contact input[type="checkbox"]{
  width: 12px;
  height: 12px;
  align-content: center;
  margin: 0px;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  top: 2px;
}
.type-selection .selection{
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  width: 64px;
}

.type-selection .selection input{
  width: 20px;
}

.type-selection label{
  margin-top: auto;
  margin-bottom: auto;
}

.entry-type .type-selection{
  width: 50px;
  height: 100%;
  justify-content: end;
}

.edit-entry{
  display: flex;
  margin-left: 20px;
}

.delete-entry{
  height: 12px;
  width: 12px;
  
  font-size: 12px;
  margin-left: 10px;
}

.edit-button{
  height: 12px;
  width: 12px;
  
  font-size: 12px;
  margin-top: 3px;
}

.delete-entry:hover{
  color: black;
  cursor: pointer;
}



.view-modal{
  max-height: 100%;
}

.view-modal .head{
  font-weight: bolder;
  cursor: pointer;
}
  
.view-modal .sort{
  border-style: none;
}

.toggle-sort{
  border-style: none;
}

.view-student-name{
  padding-top: 5px;
}

.delete-button:hover{
  font-size: 12px;
}

.log-search-bar{
  margin: 1rem;
  width: auto;
  margin-top: 0px;
}



.update-entry-save{
  width: auto;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-content: center;
  height: 100%;
  color:#838383;
  font-size: 13px;
  margin: auto;
}

.entry .contact{
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

.save-button{
  margin-left: 15px;
  font-size: 20px;
}

.view-foot{
  display: flex;
  justify-content: space-between;
}

.csv-button{
  background-color: white;
  border-style: solid;
  border-width: .2px;
  border-radius: 2px;
  margin: auto;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-left: 5px;
}

.csv-button svg{
  width: 100%;
  height: 100%;
}

.csv-manage-wrapper{
  display: flex;
}

.csv-button button{
  width: 25px;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: white;
  border-style: none;
}

.csv-modal-wrap{
  display: flex;
  justify-content: space-between;
}

.download-template-wrap{
  display: inline;
  width: 45px;
  text-align: center;
  justify-content: center;
  font-size: 10px;
}

.download-template-wrap svg{
  font-size: 20px;
}

.download-template-wrap button{
  border-style: solid;
  border-color: black;
  border-radius: 3px;
  background: white;
  padding: 4px;
}

.header-error{
  color: red;
  text-align: center;
}

.csv-error{
  color:red;
  border-width: .5px;
  padding: 2px;
  text-align: center;
}

.csv-success{
  color: green;
  
  padding: 2px;
  border-width: .5px;
  text-align: center;
  align-content: center;
}

.csv-error-wrap{
  display: flex;
  border-top-style: solid;
  border-color: black;
  font-size: 12px;
  border-width: .2px;
  justify-content: space-between;
  width: 100%;
}

.error-head{
  padding: 2px;
  border-width: .5px;
}

.csv-error-count{
  color: red;
  text-align: center;
}

.student-view .course-title{
  width: auto;
  display: flex;

}

.student-view .course-title-button{
  color: #005dc3;
  cursor: pointer;
}

.student-log{
  margin-top: 30px;
  padding-bottom: 30px;
}

.student-log h4{ 
  text-align: left;
  padding-left: 70px;
  margin-bottom: 30px;
}

.student-log .log-search-bar{
  width: 100%;
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  padding-left: 27px;
}

.student-log .search-wrapper{
  width: auto;
  display: flex;
  position: absolute;
  right: 18px;
  top: 38px;
}

.student-log .search-wrapper .search-icon{
  position: relative;
  color: #a8a8a8;
  top: 3px;
  left: -209px;
  border-right-style: inset;
  border-width: 0.5px;
  border-color: #a8a8a8;
  padding-right: 3px;
  height: 24px;
}

.search-wrapper .tag-selector{
  margin-right: 15px;
}

.search-wrapper .tag-selector .dropdown .dropdown-toggle{
  width: auto;
}



.sort-button-wrapper{
  display: flex;
}

.sort-button-wrapper .sort-dropdown button{
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.sort-button-wrapper .sort-direct{
  width: 30px;
  text-align: center;
  align-content: center;
  display: flex;
  justify-content: center;
}

.sort-button-wrapper .sort-direct{
border-top-left-radius: 0px;
border-bottom-left-radius: 0px;
border-left-color: white;
border-left-style: solid;
}

.entry-edited-at{
  width: 100%;
  font-size: 8px;
  text-align: right;
  margin-bottom: -4px;
}

.info-icon{
  position: relative;
  top: -13px;
  left: -25%;
  color: gray;
}

.last-course-update{
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.course-settings-selector {
  position: absolute;
  right: 7px;
  top: 0px;
  cursor: pointer;
  display: flex;
  width: 40px;
  align-items: center;
}

.instructor .page-csv-download{
  left: 0;
  width: 20px;
}

.non-academic-toggle{
  display: flex;
}

.non-academic-toggle label{
  width: 200px;
}

.non-academic-toggle input{
  width: 20px;
  height: 20px;
}

.nonacademic-indicator{
  font-size: 13px;
  font-style: normal;
  cursor: pointer;
}

.alphabet-selector{
  display: flex;
  position: absolute;
  right: 0px;
  margin-right: 50px;
  cursor: pointer;
  color: #005dc3;
  align-items: center;
  font-size: 16px;
  height: 16px;
}

.alphabet-selector .selection{
  padding: 1px;
}

.alphabet-selector .selected{
  color: black;
  font-weight: bold;
}

.alphabet-selector .selection:hover{
  font-size: 19px;
  color: black;
  font-weight: bolder;
}

#manage-tags{
  display: flex;
  height: 25px;
  align-content: center;
  justify-content: center;
  position: absolute;
  left: 56px;
  top: 40px;
  border-style: none;
  background-color: #005dc3;
  color: white;
  border-radius: 3px;
}

.add-log-input{
  display: flex;
  margin: 5px;
  justify-content: center;
}

.add-log-input input{
  width: 85%;
}

.add-log-input button{
  border-radius: 4px;
  border-style: none;
  height: 30px;
}

.manage-tag-modal .modal-dialog{
  max-width: none;
  width: 500px;
}

.manage-tag-modal .tag{
  padding: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.tag .delete-icon:hover{
  cursor: pointer;
  color: #005dc3;
}

.tag-container{
  max-height: 507px;
  overflow-y: scroll;
}

.tag-selector .dropdown-item{
  display: flex;
  justify-content: space-between;
}

.tag-display{
  display: flex;
  width: 100%;
  border-bottom-style: solid;
  border-width: .5px;
  padding: 3px;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.tag-display .badge{
  font-weight: bolder;
  font-size: 12px;
}

.tag-display-creation{
  display: flex;
  width: 100%;
  padding: 3px;
  flex-wrap: wrap;

}

.contact-wrapper{
  display: none;
}

.contact-enabled{
  margin-right: -52%;
  margin-left: -50%;
}

.page-selector{
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: white;
}

.page-selector .selected{
  color: blue;
}

.page-selector :hover{
  font-weight: bolder;
}

.page-num{
  padding: 3px;
  cursor: pointer;
}

.on-demand-body{
  display: flex;
}

.on-demand-body .btn-primary{
  height: 30px;
  margin-left: 10px;
}

.grade-report-cutoff{
  width: 7%;
  text-align: center;
}

.on-demand-modal .modal-body{
  height: 300px;
}

.on-demand-body .scheme-selector select{
  height: 30px;
}

.on-demand-previous {
  width: 100%;
  height: 80%;
  text-align: center;
}

.on-demand-previous .row{
  display: flex;
  justify-content: space-around;
  width: 30%;
  height: 35px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  border-width: 1px;
  border-bottom-style: solid;
  padding: 3px;
  
}

.on-demand-previous .col{
  max-width: 50px;
}

.on-demand-previous .table{
  margin-left: auto;
  margin-right: auto;
  max-height: 75%;
  overflow-y: scroll;
}

.running-spinner{
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
















